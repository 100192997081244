import { useEffect, useState, useLayoutEffect } from 'react';

export const useScrollY = () => {
    const [scrollY, setScrollY] = useState(0);
  
    useEffect(() => {
      function handleScroll() {
        setScrollY(window.scrollY)
      };
  
      window.addEventListener('scroll', handleScroll, { passive: true });
  
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return scrollY
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}